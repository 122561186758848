export function orderList(prop, dir) {
  var sortOrder
  switch(dir) {
    case 'desc': {
      sortOrder = -1
      break
    }
    default: {
      sortOrder = 1
      break
    }
  }

  return function ( a, b ) {
    var result = (a[prop] < b[prop]) ? -1 : (a[prop] > b[prop]) ? 1 : 0;
    return result * sortOrder;
  }
}
