function groupBy(key) {
  return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key]
      acc[property] = acc[property] || []
      acc[property].push(obj)
      return acc
    }, {})
  }
}

export const groupByYear = groupBy("year")
export const groupByMonth = groupBy("month")
export const groupByDay = groupBy("day")

export function padTime(t) {
  const p = ( t < 10 ? '0' : '' ) + t
  return p
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function monthFrom(m) {
  const month = MONTHS[m]
  return month
}
