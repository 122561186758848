import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Container from '@material-ui/core/Container'
import Copyright from '../../constants/copyright'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { CardElement } from '@stripe/react-stripe-js'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { EMPTY_PRODUCT } from '../../constants/stripe'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { Link, withRouter } from 'react-router-dom'
import { setNewUser } from '../../redux/user/actions'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'
import * as ROUTES from '../../constants/routes'
import * as PLANS from '../../constants/paymentPlans'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}

const PaymentForm = () => (
  <Grid item xs={12}>
    <Card>
      <CardHeader title='Card Details'/>
      <CardContent>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </CardContent>
    </Card>
  </Grid>
)

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
)

class SignUpFormBase extends Component {

  onSubmit = event => {
    const { username, email, passwordOne } = this.state

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          })
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification()
      })
      .then(() => {
        // ==> SET THE NEW USER TO NOTHING <==
        this.props.history.push(ROUTES.HOME)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    const u = Object.assign(this.props.newUser, { [event.target.name]: event.target.value })
    this.props.dispatch(setNewUser(u))
  }

  render() {
    const { classes, newUser } = this.props
    const selected = Object.assign({}, EMPTY_PRODUCT, this.props.selected)

    const isInvalid =
      newUser.passwordOne !== newUser.passwordTwo ||
      newUser.passwordOne === '' ||
      newUser.email === '' ||
      newUser.username === ''

    return (
      <React.Fragment>
        <CssBaseline />

        {/* Start hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h5" variant="h6" align="center" color="textPrimary" gutterBottom>
            { selected.unit_amount && selected.unit_amount > 0 ? 'Payment Details' : 'Your Details' }
          </Typography>
        </Container>
        {/* End hero unit */}

        <Container component="main" maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField autoComplete="uname" name="username" variant="outlined" required fullWidth
                id="username" label="User Name" autoFocus value={newUser.username} onChange={this.onChange}/>
            </Grid>
            <Grid item xs={12}>
              <TextField autoComplete="email" name="email" variant="outlined" required fullWidth
                id="email" label="Email Address" value={newUser.email} onChange={this.onChange}/>
            </Grid>
            <Grid item xs={12}>
              <TextField autoComplete="current-password" name="passwordOne" variant="outlined" required fullWidth
                id="passwordOne" label="Password" type="password" value={newUser.passwordOne} onChange={this.onChange}/>
            </Grid>
            <Grid item xs={12}>
              <TextField name="passwordTwo" variant="outlined" required fullWidth
                id="passwordTwo" label="Confirm Password" type="password" value={newUser.passwordTwo} onChange={this.onChange}/>
            </Grid>
            { selected.unit_amount && selected.unit_amount > 0 ? <PaymentForm /> : '' }
          </Grid>

          <Button type="submit" disabled={isInvalid} fullWidth
            variant="contained" color="primary" className={classes.submit}>
            Sign Up
          </Button>

          {newUser.error && <p>{newUser.error.message}</p>}

          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </React.Fragment>
    )
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={`${ROUTES.SIGN_UP}/${PLANS.FREE}`}>Sign Up</Link>
  </p>
)

const mapStateToProps = state => ({
  selected: getValueFromState(state, stateKeys.SELECTED_PRODUCT),
  newUser: getValueFromState(state, stateKeys.NEW_USER),
})

const SignUpForm = compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(mapStateToProps),
)(SignUpFormBase)

export default SignUpPage

export { SignUpForm, SignUpLink }
