import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Link } from 'react-router-dom'
import { toLocalDate } from '../../utils/DateUtils'
import * as ROUTES from '../../constants/routes'

const EMPTY_ROW = {name: null, opened: null, closed: null, actions: null}
const LINK_CHARS = 50
const LINK_ELLIPSIS = ' ...'

class CaseTableRow extends React.Component {

  navigateLink = (text, id) => {
    const link = text.length > LINK_CHARS ? text.substr(0, LINK_CHARS) + LINK_ELLIPSIS : text
    return (
      <Link to={`${ROUTES.CASE}/${id}`}>
        {link}
      </Link>
    )
  }

  deleteMe = () => {
    this.props.delete(this.props.c)
  }

  render() {
    const { c } = this.props
    const row = Object.assign({}, EMPTY_ROW, c)
    const link = this.navigateLink(row.name, row.id)

    return (
      <TableRow>
        <TableCell>{link}</TableCell>
        <TableCell align="left">{toLocalDate(row.opened)}</TableCell>
      </TableRow>
    )
  }

}

export default CaseTableRow
