import Button from '@material-ui/core/Button'
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip'

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Tooltip title="Logout" placement="bottom">
    <Button variant="outlined" color="primary" onClick={firebase.doSignOut}>Sign Out</Button>
  </Tooltip>
);

export default withFirebase(SignOutButton);
