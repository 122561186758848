import axios from 'axios'

const BASE_URL = 'http://localhost:5001/my-case-track/us-central1/'
const CREATE_STRIPE_USER_URL = BASE_URL + 'createCustomer'

export default class UserAPI {

  static async createUser(action, firebase) {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }

    const stripeResult = await axios.post(CREATE_STRIPE_USER_URL, {email: action.payload.user.email}, config)
    const firebaseResult = await firebase.doCreateUserWithEmailAndPassword(action.payload.user.email, action.payload.user.passwordOne)
    await firebase.user(firebaseResult.user.uid).set({
      username: action.payload.user.username,
      email: action.payload.user.email,
      stripeId: stripeResult.data.customer.id,
    })
    firebase.doSendEmailVerification()

    return {
      ok: true,
      user: {
        ...firebaseResult.user,
        stripeId: stripeResult.data.customer.id,
      }
    }

  }

}
