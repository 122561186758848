import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {withFirebase} from '../Firebase';
import * as actions from '../../redux/user/actions'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }

    componentDidMount() {
      const { firebase, onSetAuthUser } = this.props
      this.listener = firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          onSetAuthUser(authUser);
        },
        () => {
          localStorage.removeItem('authUser')
          onSetAuthUser(null)
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser =>
      dispatch(actions.setUser(authUser)),
  });

  return compose(
    withFirebase,
    connect(null, mapDispatchToProps)
  )(WithAuthentication);
};

export default withAuthentication;
