import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'

const styles = () => ({ })

class AssetDialog extends React.Component {

  handleChange = (e) => {
    const update = Object.assign({}, this.props.asset, { [e.target.name]: e.target.value })
    this.props.onChange(update)
  }

  save = asset => {
    const exists = typeof (asset.id) !== "undefined"
    if(exists) {
      this.props.updateAsset(asset)
    } else {
      this.props.createAsset(asset)
    }
  }

  render() {
    const { open } = this.props

    const { asset } = this.props
    const exists = typeof (asset.id) !== "undefined"

    const { description, name } = asset

    const title = exists ? name : "New Asset"

    return (
      <Dialog open={open} onClose={this.props.close} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>

          <form>
            <TextField autoFocus value={name} margin="dense"
              id="name" label="Name" name="name"
              onChange={this.handleChange} fullWidth />

            <TextField value={description} margin="dense"
               id="description" label="Description" name="description"
               onChange={this.handleChange} fullWidth multiline/>

          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.clear} color="primary">
            Clear
          </Button>
          <Button onClick={() => this.save(this.props.asset)} color="secondary" variant="contained">
            {exists ? "Update" : "Create"}
          </Button>
          <Button onClick={this.props.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

export default compose(
  connect(),
  withStyles(styles),
) (AssetDialog)
