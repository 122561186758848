import React from 'react'
import {
  clearAssetDialog,
  clearValuationDialog,
  closeAssetDialog,
  closeValuationDialog,
  openAssetDialog,
  openValuationDialog,
  selectAsset,
  updateAsset,
  updateValuation
} from '../../redux/assets/actions'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {getValueFromState, stateKeys} from "../../redux/utils/stateUtils";
import {withFirebase} from '../Firebase'
import {withAuthentication, withAuthorisation, withSubscription} from "../Session";
import {withStyles} from '@material-ui/core/styles'
import {Card, CardActions, CssBaseline} from "@material-ui/core";
import withTitle from "../Decorators/withTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Asset from "./Asset";
import Valuations from "./Valuations";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ValuationDialog from "./ValuationDialog";
import {EMPTY_ASSET, EMPTY_VALUATION} from "../../constants/assets";
import CardHeader from "@material-ui/core/CardHeader";
import AssetDialog from "./AssetDialog";

const styles = theme => ({
  card: {
    margin: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
})

class AssetsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      assetObserver: null,
      assets: [],
      selected: '',
    }
  }

  componentDidMount() {
    const { firebase } = this.props
    this.setState({
      assetObserver: firebase.getAssets(assets => {
        this.setState({
          assets: assets
        })
      })
    })
  }

  componentWillUnmount() {
    const { assetObserver } = this.state
    assetObserver()
  }

  newAsset = () => {
    const { dispatch } = this.props
    dispatch(openAssetDialog(EMPTY_ASSET))
  }

  newValuation = () => {
    const { dispatch } = this.props
    dispatch(openValuationDialog(EMPTY_VALUATION))
  }

  closeAssetDialog = () => {
    const { dispatch } = this.props
    dispatch(closeAssetDialog())
  }

  closeValuationDialog = () => {
    const { dispatch } = this.props
    dispatch(closeValuationDialog())
  }

  clearAssetDialog = () => {
    const { dispatch } = this.props
    dispatch(clearAssetDialog())
  }

  clearValuationDialog = () => {
    const { dispatch } = this.props
    dispatch(clearValuationDialog())
  }

  createAsset = asset => {
    const { dispatch, firebase } = this.props
    firebase.createAsset(asset)
    dispatch(closeAssetDialog())
    dispatch(clearAssetDialog())
  }

  updateAsset = asset => {
    const { dispatch, firebase } = this.props
    const { id } = asset
    delete asset.id
    firebase.updateAsset(id, asset)
    dispatch(closeAssetDialog())
    dispatch(clearAssetDialog())
  }

  createValuation = (asset, valuation) => {
    const { dispatch, firebase } = this.props
    firebase.createValuation(asset.id, valuation)
    dispatch(selectAsset(asset))
    dispatch(closeValuationDialog())
    dispatch(clearValuationDialog())
  }

  updateValuation = (asset, valuation) => {
    const { dispatch, firebase } = this.props
    const { id } = asset
    const valuationId = valuation.id
    delete valuation.id
    firebase.updateValuation(id, valuationId, valuation)
    dispatch(closeValuationDialog())
    dispatch(clearValuationDialog())
  }

  onChange = asset => {
    const { dispatch } = this.props
    dispatch(updateAsset(asset))
  }

  onValuationChange = valuation => {
    const { dispatch } = this.props
    dispatch(updateValuation(valuation))
  }

  handleItemClick = item => {
    const { assets, selected } = this.state
    this.setState({
      selected: selected === item ? '' : item
    })
    const { dispatch } = this.props
    dispatch(selectAsset(assets[item]))
  }

  handleItemDelete = index => {
    const { assets } = this.state
    const { dispatch, firebase } = this.props
    const item = assets[index]
    firebase.deleteAsset(item.id)
    dispatch(selectAsset({}))
  }

  handleItemEdit = index => {
    const { assets } = this.state
    const { dispatch } = this.props
    dispatch(openAssetDialog(assets[index]))
  }

  handleValuationEdit = valuation => {
    const { dispatch } = this.props
    dispatch(openValuationDialog(valuation))
  }

  handleValuationDelete = (assetId, valuationId) => {
    const { firebase } = this.props
    firebase.deleteValuation(assetId, valuationId)
  }

  render() {
    const { asset, assetDialogOpen, classes, valuationDialogOpen } = this.props
    const assetSelected = typeof(asset.id) !== "undefined"
    const { assets, selected } = this.state
    return (
      <div className={classes.content}>
        <CssBaseline />
          <Grid
            container
            direction="row"
            justifycontent="center"
            alignItems="flex-start"
          >
            <Grid item sm={4}>
              <Card className={classes.card} elevation={3}>
                <CardContent>
                  <List>
                    {
                      assets.map((a, index) => {
                        return <Asset key={index} index={index} selected={selected} asset={a} handleItemClick={this.handleItemClick} handleItemDelete={this.handleItemDelete} handleItemEdit={this.handleItemEdit} />
                      })
                    }
                  </List>
                </CardContent>
                <CardActions>
                  <Button color="primary" size="small" onClick={() => this.newAsset()}>New Asset</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item sm={8}>
              <Card className={classes.card} elevation={3} hidden={!assetSelected}>
                <CardHeader title="Valuations" />
                <CardContent>
                  <Valuations handleValuationEdit={this.handleValuationEdit} handleValuationDelete={this.handleValuationDelete} />
                </CardContent>
                <CardActions>
                  <Button color="primary" size="small" disabled={!assetSelected} onClick={() => this.newValuation()}>New Valuation</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        <AssetDialog open={assetDialogOpen} createAsset={this.createAsset} updateAsset={this.updateAsset} close={this.closeAssetDialog} clear={this.clearAssetDialog} asset={asset} onChange={this.onChange} />
        <ValuationDialog open={valuationDialogOpen} createValuation={this.createValuation} updateValuation={this.updateValuation} close={this.closeValuationDialog} clear={this.clearValuationDialog} onChange={this.onValuationChange} />
      </div>
    )
  }
}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  assetDialogOpen: getValueFromState(state, stateKeys.ASSET_DIALOG_OPEN),
  asset: getValueFromState(state, stateKeys.ASSET).toJS(),
  valuationDialogOpen: getValueFromState(state, stateKeys.VALUATION_DIALOG_OPEN)
})

export default compose(
  withAuthentication,
  withAuthorisation(condition),
  withSubscription,
  withFirebase,
  withStyles(styles),
  withTitle("Assets"),
  connect(mapStateToProps),
)(AssetsPage)
