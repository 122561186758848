import { EMPTY_EVENT } from '../../constants/cases'
import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  dialogOpen: false,
  event: EMPTY_EVENT
})

const timeline = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_EVENT_DETAIL_DIALOG: {
      const withEvent = setValueInState(state, stateKeys.NEW_EVENT, action.event)
      return setValueInState(withEvent, stateKeys.NEW_EVENT_DAILOG_OPEN, true)
    }

    case actions.CLOSE_EVENT_DETAIL_DIALOG: {
      return setValueInState(state, stateKeys.NEW_EVENT_DAILOG_OPEN, false)
    }

    case actions.CLEAR_EVENT_DETAIL_DIALOG: {
      return setValueInState(state, stateKeys.NEW_EVENT, EMPTY_EVENT)
    }

    case actions.UPDATE_NEW_EVENT: {
      return setValueInState(state, stateKeys.NEW_EVENT, action.event)
    }

    default: {
      return state
    }

  }

}

export default timeline
