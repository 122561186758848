import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  dialogOpen: false,
  text: ''
})

const caseDescription = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_CASE_DESCRIPTION_DIALOG: {
      const withText = setValueInState(state, stateKeys.CASE_DESCRIPTION_TEXT, action.payload.text)
      return setValueInState(withText, stateKeys.CASE_DESCRIPTION_DIALOG_OPEN, true)
    }

    case actions.CLOSE_CASE_DESCRIPTION_DIALOG: {
      const withoutText = setValueInState(state, stateKeys.CASE_DESCRIPTION_TEXT, '')
      return setValueInState(withoutText, stateKeys.CASE_DESCRIPTION_DIALOG_OPEN, false)
    }

    case actions.UPDATE_CASE_DESCRIPTION: {
      return setValueInState(state, stateKeys.CASE_DESCRIPTION_TEXT, action.payload.text)
    }

    default: {
      return state
    }

  }

}

export default caseDescription
