import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { closeCaseDescriptionDialog, updateCaseDescription } from '../../redux/caseDescription/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'

class CaseDescriptionDialog extends React.Component {

  handleChange = (e) => {
    const { dispatch } = this.props
    dispatch(updateCaseDescription({
      payload: {
        text: e.target.value
      }
    }))
  }

  closeMe = () => {
    const { dispatch } = this.props
    dispatch(closeCaseDescriptionDialog())
  }

  save = () => {
    const { dispatch, save, text } = this.props
    save({
      description: text
    })
    dispatch(closeCaseDescriptionDialog())
  }

  render() {
    const { open, text } = this.props
    const valid = text.length > 0

    return (
      <Dialog open={open} onClose={this.closeMe} maxWidth="md" fullWidth={true} aria-labelledby="case-description-dialog">
        <DialogContent>

          <form>
            <TextField autoFocus value={text} margin="dense" id="description"
              label="Description" name="description" multiline fullWidth
              onChange={this.handleChange} />
          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.save} color="secondary" variant="contained" disabled={!valid}>
            Save
          </Button>
          <Button onClick={this.closeMe} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

CaseDescriptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.CASE_DESCRIPTION_DIALOG_OPEN),
  text: getValueFromState(state, stateKeys.CASE_DESCRIPTION_TEXT)
})

export default compose(
  connect(mapStateToProps),
)(CaseDescriptionDialog)
