import { fromJS } from 'immutable'

export function getValueFromState(state, stateKey) {
  stateKey = convertStateKey(state, stateKey)
  return state.getIn(stateKey.split('.'))
}

export function setValueInState(state, stateKey, value) {
  stateKey = convertStateKey(state, stateKey)
  return state.setIn(stateKey.split('.'), fromJS(value))
}

function convertStateKey(state, stateKey) {
  let reducer = stateKey.substr(0, stateKey.indexOf('.'))
  if(state.has(reducer)) {
    return stateKey
  } else {
    return stateKey.substr(stateKey.indexOf('.') + 1)
  }
}

export let stateKeys = {
  PRODUCTS: 'productList.products',
  SELECTED_PRODUCT: 'productList.selected',
  PRODUCTS_FAILURE: 'productList.failure',
  PRODUCTS_ERROR: 'productList.error',

  AUTH_USER: 'userState.authUser',
  NEW_USER: 'userState.newUser',
  CREATE_SUCCESS: 'userState.createSuccess',
  STRIPE_USER: 'userState.stripeUser',
  USER_FAILURE: 'userState.failure',
  USER_ERROR: 'userState.error',
  USER_BUSY: 'userState.busy',

  CHECKOUT_SESSION_BUSY: 'checkout.busy',
  CHECKOUT_SESSION_ERROR: 'checkout.error',

  NEW_CASE_DAILOG_OPEN: 'newCase.dialogOpen',
  NEW_CASE: 'newCase._case',

  CASE_ID: 'caseDetail.case.id',
  SHOW_TIMELINE_BUTTON: 'internalDrawer.showTimelineButton',
  SHOW_CASE_HOME_BUTTON: 'internalDrawer.showCaseHomeButton',
  SHOW_PEOPLE_BUTTON: 'internalDrawer.showPeopleButton',

  SELECTED_CASE: 'caseSummary.selectedCase',
  CASE_DETAIL_DIALOG_OPEN: 'caseSummary.dialogOpen',
  CASE_DETAIL: 'caseSummary.case',

  CASE_NAME_DIALOG_OPEN: 'caseName.dialogOpen',
  CASE_NAME_TEXT: 'caseName.text',
  CASE_DESCRIPTION_DIALOG_OPEN: 'caseDescription.dialogOpen',
  CASE_DESCRIPTION_TEXT: 'caseDescription.text',

  NEW_PERSON_DIALOG_OPEN: 'people.newPersonDialogOpen',
  NEW_PERSON: 'people.newPerson',

  ASSET_DIALOG_OPEN: 'assets.assetDialogOpen',
  ASSET: 'assets.asset',
  VALUATION_DIALOG_OPEN: 'assets.valuationDialogOpen',
  VALUATION: 'assets.valuation',

  NEW_EVENT_DAILOG_OPEN: 'newEvent.dialogOpen',
  NEW_EVENT: 'newEvent.event'
}
