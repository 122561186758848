export const OPEN_ASSET_DIALOG = 'OPEN_ASSET_DIALOG'
export const CLOSE_ASSET_DIALOG = 'CLOSE_ASSET_DIALOG'
export const CLEAR_ASSET_DIALOG = 'CLEAR_ASSET_DIALOG'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const OPEN_VALUATION_DIALOG = 'OPEN_VALUATION_DIALOG'
export const CLOSE_VALUATION_DIALOG = 'CLOSE_VALUATION_DIALOG'
export const CLEAR_VALUATION_DIALOG = 'CLEAR_VALUATION_DIALOG'
export const UPDATE_VALUATION = 'UPDATE_VALUATION'
export const SELECT_ASSET = 'SELECT_ASSET'

export const openAssetDialog = asset => ({
  type: OPEN_ASSET_DIALOG,
  payload: {
    asset: asset
  }
})

export const closeAssetDialog = () => ({
  type: CLOSE_ASSET_DIALOG
})

export const clearAssetDialog = () => ({
  type: CLEAR_ASSET_DIALOG
})

export const updateAsset = update => ({
  type: UPDATE_ASSET,
  update: update
})

export const openValuationDialog = valuation => ({
  type: OPEN_VALUATION_DIALOG,
  payload: {
    valuation: valuation
  }
})

export const closeValuationDialog = () => ({
  type: CLOSE_VALUATION_DIALOG
})

export const clearValuationDialog = () => ({
  type: CLEAR_VALUATION_DIALOG
})

export const updateValuation = update => ({
  type: UPDATE_VALUATION,
  update: update
})

export const selectAsset = asset => ({
  type: SELECT_ASSET,
  payload: {
    asset: asset
  }
})
