import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Prices from './Prices'
import React from 'react'
import StarIcon from '@material-ui/icons/StarBorder'
import * as ROUTES from '../../constants/routes'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.light,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
})

class Product extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      prices: []
    }
  }

  componentDidMount() {
    const { id } = this.props.product
    this.props.firebase.getPrices(id, prices => {
      this.setState({
        prices: prices
      })
    })
  }

  render() {
    const { classes, product } = this.props
    const { prices } = this.state

    return (
      <Grid item key={product.name} xs={12} md={4}>
        <Card raised={product.selected}>
          <CardHeader title={product.name} subheader={product.description}
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{ align: 'center' }}
            action={product.name === 'Premium' ? <StarIcon /> : null} className={classes.cardHeader}
          />
          <CardContent>
            <Prices prices={prices} />
          </CardContent>
          <CardActions>
            <Button fullWidth variant='contained' color='primary' component={Link} to={ROUTES.SIGN_IN}>Select</Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

}

export default compose(
  withFirebase,
  withStyles(styles),
)(Product)
