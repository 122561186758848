import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import {getValueFromState, stateKeys} from "../../redux/utils/stateUtils";
import {DatePicker} from "@material-ui/pickers";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {CURRENCIES, DEFAULT_CURRENCY} from "../../constants/assets";
import Grid from "@material-ui/core/Grid";

const styles = () => ({ })

class ValuationDialog extends React.Component {

  handleChange = (e) => {
    const { valuation } = this.props
    const update = Object.assign({}, valuation, { [e.target.name]: e.target.value })
    this.props.onChange(update)
  }

  handleDateChange = (e) => {
    const { valuation } = this.props
    const date = (new Date(e)).setHours(0,0,0,0)
    const update = Object.assign({}, valuation, { date: +date })
    this.props.onChange(update)
  }

  handleNumberChange = (e) => {
    if(e.target.value.match(/\d+/g) || e.target.value === '') {
      const { valuation } = this.props
      const update = Object.assign({}, valuation, { [e.target.name]: e.target.value })
      this.props.onChange(update)
    }
  }

  currencies = () => {
    return CURRENCIES.map((c, index) => {
      return <MenuItem key={index} value={c.name}>{c.name} ({c.symbol})</MenuItem>
    })
  }

  save = valuation => {
    const { asset } = this.props
    const exists = typeof(valuation.id) !== "undefined"
    if(exists) {
      this.props.updateValuation(asset, valuation)
    } else {
      this.props.createValuation(asset, valuation)
    }
  }

  render() {
    const { asset, open, valuation } = this.props
    const exists = typeof (valuation.id) !== "undefined"
    const title = `${exists ? 'Edit' : 'New'} Valuation for ${asset.name}`

    const currency = CURRENCIES.filter(c => c.name === valuation.currency)
    const symbol = currency.length === 0 ? DEFAULT_CURRENCY.symbol : currency[0].symbol

    const inputProps = {
      startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
    }

    return (
      <Dialog open={open} onClose={this.props.close} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>

          <form>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <DatePicker autoFocus value={valuation.date} margin="dense" label="Date" name="date" fullWidth
                            disableFuture openTo="year" format="dd/MM/yyyy" views={["year", "month", "date"]}
                            onChange={this.handleDateChange} />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select labelId="currency-label" value={valuation.currency} name="currency"
                          onChange={this.handleChange} >
                    {this.currencies()}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField value={valuation.value} margin="dense"
                           id="value" label="Value" name="value"
                           onChange={this.handleNumberChange} fullWidth
                           InputProps={inputProps} />
              </Grid>
              <Grid item xs={12}>
                <TextField value={valuation.type} margin="dense"
                           id="type" label="Type" name="type"
                           onChange={this.handleChange} fullWidth />
              </Grid>
            </Grid>
          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.clear} color="primary">
            Clear
          </Button>
          <Button onClick={() => this.save(valuation)} color="secondary" variant="contained">
            {exists ? "Update" : "Create"}
          </Button>
          <Button onClick={this.props.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

const mapStateToProps = state => ({
  asset: getValueFromState(state, stateKeys.ASSET).toJS(),
  valuation: getValueFromState(state, stateKeys.VALUATION).toJS(),
})

export default compose(
  connect(),
  withStyles(styles),
  connect(mapStateToProps),
) (ValuationDialog)
