import { EMPTY_USER } from '../../constants/stripe'
import { fromJS } from 'immutable'
import { stateKeys, setValueInState } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  authUser: null,
  newUser: EMPTY_USER,
  createSuccess: false,
  busy: false,
  failure: null,
  error: null,
})

const userState = (state = initialState, action) => {

  switch(action.type) {

    case actions.SET_USER: {
      return setValueInState(state, stateKeys.AUTH_USER, action.payload.user)
    }

    case actions.UNSET_USER: {
      return setValueInState(state, stateKeys.AUTH_USER, null)
    }

    case actions.SET_NEW_USER: {
      return setValueInState(state, stateKeys.NEW_USER, action.payload.newUser)
    }

    case actions.CREATE_USER_BEGIN: {
      console.log('CREATE_USER_BEGIN', action)
      return setValueInState(state, stateKeys.USER_BUSY, true)
    }

    case actions.CREATE_USER_SUCCESS: {
      console.log('CREATE_USER_SUCCESS', action)
      const notBusy = setValueInState(state, stateKeys.USER_BUSY, false)
      const withSuccess = setValueInState(notBusy, stateKeys.CREATE_SUCCESS, true)
      return setValueInState(withSuccess, stateKeys.AUTH_USER, action.payload.customer)
    }

    case actions.CREATE_USER_FAILURE: {
      console.log('CREATE_USER_FAILURE', action)
      const notBusy = setValueInState(state, stateKeys.USER_BUSY, false)
      return setValueInState(notBusy, stateKeys.USER_FAILURE, action.payload.failure)
    }

    case actions.CREATE_USER_ERROR: {
      console.log('CREATE_USER_ERROR', action)
      const notBusy = setValueInState(state, stateKeys.USER_BUSY, false)
      return setValueInState(notBusy, stateKeys.USER_ERROR, action.payload.error)
    }

    default: {
      return state
    }
  }

}

export default userState
