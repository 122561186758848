import React from 'react'
import Month from './Month'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

class Year extends React.Component {

  render() {
    const { year } = this.props
    const calendarYear = Object.keys(year)[0]
    const months = year[calendarYear].months
    return (
      <div>
        <Typography component="h4" variant="h4" align="left" color="textPrimary">
          { calendarYear }
        </Typography>
        {
          months.map((m, index) => {
            return (
              <Month key={index} month={m} />
            )
          })
        }
      </div>
    )
  }

}

export default compose(
  withStyles(styles)
)(Year)
