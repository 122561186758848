import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  dialogOpen: false,
  text: ''
})

const caseName = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_CASE_NAME_DIALOG: {
      const withText = setValueInState(state, stateKeys.CASE_NAME_TEXT, action.payload.text)
      return setValueInState(withText, stateKeys.CASE_NAME_DIALOG_OPEN, true)
    }

    case actions.CLOSE_CASE_NAME_DIALOG: {
      const withoutText = setValueInState(state, stateKeys.CASE_NAME_TEXT, '')
      return setValueInState(withoutText, stateKeys.CASE_NAME_DIALOG_OPEN, false)
    }

    case actions.UPDATE_CASE_NAME: {
      return setValueInState(state, stateKeys.CASE_NAME_TEXT, action.payload.text)
    }

    default: {
      return state
    }

  }

}

export default caseName
