import { EMPTY_PERSON } from '../../constants/cases'
import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  newPersonDialogOpen: false,
  newPerson: EMPTY_PERSON,
})

const people = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_NEW_PERSON_DIALOG: {
      return setValueInState(state, stateKeys.NEW_PERSON_DIALOG_OPEN, true)
    }

    case actions.CLOSE_NEW_PERSON_DIALOG: {
      return setValueInState(state, stateKeys.NEW_PERSON_DIALOG_OPEN, false)
    }

    case actions.CLEAR_NEW_PERSON_DIALOG: {
      return setValueInState(state, stateKeys.NEW_PERSON, EMPTY_PERSON)
    }

    case actions.UPDATE_NEW_PERSON: {
      return setValueInState(state, stateKeys.NEW_PERSON, action.update)
    }

    default: {
      return state
    }

  }

}

export default people
