import CaseDescription from './CaseDescription'
import CaseName from './CaseName'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {getValueFromState, stateKeys} from '../../redux/utils/stateUtils'
import {selectCase} from '../../redux/case/actions'
import {withAuthentication, withAuthorisation, withSubscription} from '../Session'
import {withFirebase} from '../Firebase'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  rootLink: {
    cursor: 'pointer',
  }
})

class CasePage extends React.Component {

  constructor(props) {
    super(props)
    this.save = this.save.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.match.params
    const { dispatch, firebase } = this.props
    firebase.getCase(id, c => {
      dispatch(selectCase(c))
    })
  }

  save = update => {
    const { selectedCase, dispatch, firebase } = this.props
    firebase.updateCase(selectedCase.id, update)
      .then(() => {
        firebase.getCase(selectedCase.id, c => {
          dispatch(selectCase(c))
        })
      })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <CaseName variant="h3" save={this.save} />
        <div className={classes.content}>
          <CssBaseline />
          <CaseDescription save={this.save} />
        </div>
      </div>
    )
  }

}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  selectedCase: getValueFromState(state, stateKeys.SELECTED_CASE).toJS(),
})

export default compose(
  withAuthentication,
  withAuthorisation(condition),
  withSubscription,
  connect(mapStateToProps),
  withFirebase,
  withStyles(styles),
)(CasePage)
