import { EMPTY_CASE } from '../../constants/cases'
import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  dialogOpen: false,
  _case: EMPTY_CASE,
})

const newCase = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_NEW_CASE_DIALOG: {
      const date = new Date()
      date.setHours(12, 0, 0, 0)
      const emptyCase = Object.assign({}, EMPTY_CASE, { opened : date })
      const withEmptyCase = setValueInState(state, stateKeys.NEW_CASE, emptyCase)
      return setValueInState(withEmptyCase, stateKeys.NEW_CASE_DAILOG_OPEN, true)
    }

    case actions.CLOSE_NEW_CASE_DIALOG: {
      const withoutCase = setValueInState(state, stateKeys.NEW_CASE, EMPTY_CASE)
      return setValueInState(withoutCase, stateKeys.NEW_CASE_DAILOG_OPEN, false)
    }

    case actions.CLEAR_NEW_CASE_DIALOG: {
      const date = new Date()
      date.setHours(12, 0, 0, 0)
      const emptyCase = Object.assign({}, EMPTY_CASE, { opened : date })
      return setValueInState(state, stateKeys.NEW_CASE, emptyCase)
    }

    case actions.UPDATE_NEW_CASE: {
      return setValueInState(state, stateKeys.NEW_CASE, action.update)
    }

    default: {
      return state
    }

  }

}

export default newCase
