import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import AccountPage from '../Account'
import AdminPage from '../Admin'
import AssetsPage from "../Assets"
import CasePage from '../Case'
import Drawer from '../Drawer'
import Home from '../Home'
import LandingPage from '../Landing'
import Navigation from '../Navigation'
import PageStructure from '../PageStructure'
import PasswordForgetPage from '../PasswordForget'
import Payment from '../Payment'
import PeoplePage from '../People'
import Pricing from '../Pricing'
import Products from '../Products'
import SignInPage from '../SignIn'
import SignUpPage from '../SignUp'
import TimelinePage from '../Timeline'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Session'

const App = () => (
  <Router >
    <div style={{display: 'flex'}}>
      <CssBaseline />
      <Navigation />
      <Drawer />

      <Switch>
        <RouteWrapper exact path={ROUTES.LANDING} component={LandingPage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.ACCOUNT} component={AccountPage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.ADMIN} component={AdminPage} layout={PageStructure}/>
        <RouteWrapper path={`${ROUTES.CASE}/:id`} component={CasePage} layout={PageStructure}/>
        <RouteWrapper path={`${ROUTES.TIMELINE}/:id`} component={TimelinePage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.PEOPLE} component={PeoplePage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.ASSETS} component={AssetsPage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.HOME} component={Home} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.PAYMENT} component={Payment} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.PRICING} component={Pricing} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.PRODUCTS} component={Products} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.SIGN_UP} component={SignUpPage} layout={PageStructure}/>
        <RouteWrapper path={ROUTES.SIGN_IN} component={SignInPage} layout={PageStructure}/>
      </Switch>
    </div>
  </Router>
)

function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <Route {...rest} render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )} />
  )
}

export default withAuthentication(App)
