import Button from '@material-ui/core/Button';
import ConfirmationSent from '../../constants/confirmationSent'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react'
import VerifyEmail from '../../constants/verifyEmail'
import { compose } from 'recompose';
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const needsEmailVerification = authUser =>
  authUser
  && !authUser.emailVerified
  && authUser.providerData.map(provider => provider.providerId).includes('password')

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props)
      this.state = { isSent: false }
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }))
    }

    render() {
      const { classes } = this.props

      return (
        needsEmailVerification(this.props.authUser) ? (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              {this.state.isSent ? <ConfirmationSent /> : <VerifyEmail />}
            </div>
            <Button onClick={this.onSendEmailVerification} disabled={this.state.isSent}
              variant="contained" color="primary" className={classes.submit}>
              Send confirmation E-Mail
            </Button>
          </Container>
        ) : (
          <Component {...this.props} />
        )
      )
    }
  }

  return compose(
    withStyles(styles),
    withFirebase,
  )(WithEmailVerification)
}

export default withEmailVerification
