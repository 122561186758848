import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PeopleIcon from '@material-ui/icons/People'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import WorkIcon from '@material-ui/icons/Work'
import { compose } from 'recompose'
import { DRAWER_WIDTH } from '../../constants/toolbars'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import * as ROUTES from '../../constants/routes'

const styles = ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
})

class RootDrawer extends React.Component {

  render() {
    const { classes } = this.props

    const casesButton = (
      <ListItem component={Link} to={ROUTES.LANDING}>
        <ListItemIcon><WorkIcon/></ListItemIcon>
        <ListItemText primary="Cases" />
      </ListItem>
    )

    const peopleButton = (
      <ListItem component={Link} to={ROUTES.PEOPLE}>
        <ListItemIcon><PeopleIcon/></ListItemIcon>
        <ListItemText primary="People" />
      </ListItem>
    )

    const assetsButton = (
      <ListItem component={Link} to={ROUTES.ASSETS}>
        <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
        <ListItemText primary="Assets" />
      </ListItem>
    )

    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            { casesButton }
            { peopleButton }
            { assetsButton }
          </List>
        </div>
      </Drawer>
    )
  }

}

export default compose(
  withStyles(styles),
)(RootDrawer)
