export const OPEN_NEW_CASE_DIALOG = 'OPEN_NEW_CASE_DIALOG'
export const CLOSE_NEW_CASE_DIALOG = 'CLOSE_NEW_CASE_DIALOG'
export const CLEAR_NEW_CASE_DIALOG = 'CLEAR_NEW_CASE_DIALOG'
export const UPDATE_NEW_CASE = 'UPDATE_NEW_CASE'

export const openNewCaseDialog = () => ({
  type: OPEN_NEW_CASE_DIALOG
})

export const closeNewCaseDialog = () => ({
  type: CLOSE_NEW_CASE_DIALOG
})

export const clearNewCaseDialog = () => ({
  type: CLEAR_NEW_CASE_DIALOG
})

export const updateNewCase = update => ({
  type: UPDATE_NEW_CASE,
  update: update
})
