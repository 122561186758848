import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { clearNewCaseDialog, closeNewCaseDialog, updateNewCase } from '../../redux/newCase/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({ })

class NewCaseDialog extends React.Component {

  handleClose = () => {
    this.props.dispatch(closeNewCaseDialog())
  }

  handleChange = (e) => {
    const { _case, dispatch } = this.props
    const update = Object.assign({}, _case, { [e.target.name]: e.target.value })
    dispatch(updateNewCase(update))
  }

  handleDateChange = (e) => {
    const { _case, dispatch } = this.props
    const date = new Date(e)
    date.setHours(12, 0, 0, 0)
    const update = Object.assign({}, _case, { opened: +date })
    dispatch(updateNewCase(update))
  }

  handleClear = () => {
    const { dispatch } = this.props
    dispatch(clearNewCaseDialog())
  }

  handleSave = () => {
    const { _case, dispatch, save } = this.props
    save(_case)
    dispatch(closeNewCaseDialog())
  }

  render() {
    const { _case, open } = this.props
    const title = _case.name.length === 0 ? 'Untitled Case' : _case.name
    const disableSave = _case.name.length === 0 || _case.description.length === 0

    return (
      <Dialog open={open} onClose={this.handleClose} maxWidth="md" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>

          <form>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={9}>
                <TextField autoFocus value={_case.name} margin="dense" id="name"
                  label="Case Name" name="name" onChange={this.handleChange} fullWidth />
              </Grid>
              <Grid item sm={3}>
                <KeyboardDatePicker disableToolbar variant="inline"
                  format="MMM d, yyyy" margin="dense" label="Opened"
                  value={_case.opened} onChange={this.handleDateChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField value={_case.description} margin="dense" id="description"
                  label="Detail" name="description" onChange={this.handleChange}
                  fullWidth multiline />
              </Grid>
            </Grid>

          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={() => this.handleSave()} color="secondary" variant="contained" disabled={disableSave}>
            Create
          </Button>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.NEW_CASE_DAILOG_OPEN),
  _case: getValueFromState(state, stateKeys.NEW_CASE).toJS(),
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(NewCaseDialog)
