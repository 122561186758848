export const OPEN_CASE_DETAIL_DIALOG = 'OPEN_CASE_DETAIL_DIALOG'
export const CLOSE_CASE_DETAIL_DIALOG = 'CLOSE_CASE_DETAIL_DIALOG'
export const CLEAR_CASE_DETAIL_DIALOG = 'CLEAR_CASE_DETAIL_DIALOG'
export const UPDATE_CASE_DETAIL = 'UPDATE_CASE_DETAIL'
export const SELECT_CASE = 'SELECT_CASE'
export const UNSET_CASE = 'UNSET_CASE'

export const selectCase = selectedCase => ({
  type: SELECT_CASE,
  selectedCase: selectedCase
})

export const openCaseDetailDialog = detail => ({
  type: OPEN_CASE_DETAIL_DIALOG,
  detail: detail
})

export const closeCaseDetailDialog = () => ({
  type: CLOSE_CASE_DETAIL_DIALOG
})

export const clearCaseDetailDialog = () => ({
  type: CLEAR_CASE_DETAIL_DIALOG
})

export const updateCaseDetail = update => ({
  type: UPDATE_CASE_DETAIL,
  update: update
})

export const unsetCase = () => ({
  type: UNSET_CASE,
})
