export const OPEN_CASE_DESCRIPTION_DIALOG = 'OPEN_CASE_DESCRIPTION_DIALOG'
export const CLOSE_CASE_DESCRIPTION_DIALOG = 'CLOSE_CASE_DESCRIPTION_DIALOG'
export const UPDATE_CASE_DESCRIPTION = 'UPDATE_CASE_DESCRIPTION'

export const openCaseDescriptionDialog = payload => ({
  type: OPEN_CASE_DESCRIPTION_DIALOG,
  ...payload
})

export const closeCaseDescriptionDialog = () => ({
  type: CLOSE_CASE_DESCRIPTION_DIALOG
})

export const updateCaseDescription = payload => ({
  type: UPDATE_CASE_DESCRIPTION,
  ...payload
})
