import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCjbb4FYGTxNPeIaJLAhCtMNq5cXpbrSbU',
  authDomain: 'my-case-track.firebaseapp.com',
  databaseURL: 'https://my-case-track.firebaseio.com',
  projectId: 'my-case-track',
  storageBucket: 'my-case-track.appspot.com',
  messagingSenderId: 37425962765,
  appId: '1:37425962765:web:4b3a25df9c0cb816de5a44',
  measurementId: 'G-27QBPNV900'
};

const STRIPE_PK = 'pk_test_51HoECuHMSSox4e0RAiZmwk8Akj8W0BfywTCfnXYHqjkbNFOF5yFTlM6fH9np9Kbvy4pk3rcTNQklapER5sy7AsF300whsOIuyM'

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
    this.functions = app.functions();
    this.fs = app.firestore();

    this.emailAuthProvider = app.auth.EmailAuthProvider.PROVIDER_ID
    this.googleAuthProvider = app.auth.GoogleAuthProvider.PROVIDER_ID

    this.stripePromise = window.Stripe(STRIPE_PK)
  }

  // *** Firestore & Stripe ***
  firestore = () => this.fs
  stripe = () => this.stripePromise

  // *** Auth Providers ***
  emailAuthProvider = () => this.emailAuthProvider
  googleAuthProvider = () => this.googleAuthProvider

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: 'https://mycasetrack.com',
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        next(authUser)
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  getStripeRole = (setStripRole, setError) => this.auth.currentUser.getIdTokenResult(true)
    .then(idTokenResult => {
      const { claims } = idTokenResult
      setStripRole(claims.stripeRole)
    })
    .catch((error) => {
      console.log(error)
      setError(error)
    })

  hasSubscribed = (subscribed) => this.auth.currentUser.getIdTokenResult(true)
    .then(idTokenResult => {
      const { claims } = idTokenResult
      subscribed(!!claims.stripeRole && (claims.stripeRole === 'basic' || claims.stripeRole === 'premium'))
    })
    .catch((error) => {
      console.log(error)
    })

  // *** Products API ***
  getProducts = (setProducts) => this.fs.collection('products').get()
    .then(snapshot => {
      const data = snapshot.docs.map(p => {
        return {
          id: p.id,
          ...p.data()
        }
      })
      setProducts(data)
    })

  getPrices = (productId, setPrices) => this.fs.collection('products').doc(productId)
    .collection('prices').get()
    .then(snapshot => {
      const data = snapshot.docs.map(p => {
        return {
          id: p.id,
          ...p.data()
        }
      })
      setPrices(data)
    })

  // *** Events API ***
  getEvents = (caseId, setEvents) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(caseId).collection('events').get()
    .then(snapshot => {
      const data = snapshot.docs.map(e => {
        return {
          id: e.id,
          ...e.data()
        }
      })
      setEvents(data)
    })
    .catch(error => {
      console.error(error)
    })

  createEvent = (caseId, event) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(caseId).collection('events')
    .add(event)
    .catch(e => {
      console.log('Error writing document', e)
    })

  // *** Cases API ***
  getCases = (setCases, setError) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases').where('deleted', '==', false).get()
    .then(snapshot => {
      const data = snapshot.docs.map(c => {
        return {
          id: c.id,
          ...c.data()
        }
      })
      setCases(data)
    })
    .catch(error => {
      setError(error)
    })

  getCase = (id, setCase) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(id).get()
    .then(doc => {
      setCase({
        id: doc.id,
        ...doc.data()
      })
    })
    .catch(error => {
      console.error(error)
    })

  updateCase = (id, update) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(id)
    .update(update)
    .catch(error => {
      console.error(error)
    })

  createCase = (c) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .add(c)
    .catch(e => {
      console.log('Error writing document', e)
    })

  // *** People API ***
  getPeople = (setPeople) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('people').get()
    .then(snapshot => {
      const data = snapshot.docs.map(p => {
        return {
          id: p.id,
          ...p.data()
        }
      })
      setPeople(data)
    })
    .catch(error => {
      console.error(error)
    })

  getPeopleIn = (caseId, people, setPeople) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(caseId).collection('people').where(app.firestore.FieldPath.documentId(), 'in', people).get()
    .then(snapshot => {
      const data = snapshot.docs.map(p => {
        return {
          id: p.id,
          ...p.data()
        }
      })
      setPeople(data)
    })
    .catch(error => {
      console.error(error)
    })

  createPerson = (person) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('people')
    .add(person)
    .catch(e => {
      console.log('Error writing document', e)
    })

  deletePerson = (caseId, personId) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(caseId).collection('people')
    .doc(personId).delete()
    .catch(e => {
      console.log('Error deleting document', e)
    })

  // *** Assets API ***
  getAssets = (setAssets) =>
    this.fs.collection('customers').doc(this.auth.currentUser.uid)
      .collection('assets')
      .where('deleted', '==', false)
      .onSnapshot(snapshot => {
        const data = snapshot.docs.map(a => {
          return {
            id: a.id,
            ...a.data()
          }
        })
      setAssets(data)
      })

  getAssetValuations = (assetId, setValuations) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('assets')
    .doc(assetId).collection('valuations').orderBy('date')
    .onSnapshot(snapshot => {
      const data = snapshot.docs.map(v => {
        return {
          id: v.id,
          ...v.data()
        }
      })
      setValuations(data)
    })

  getAssetsIn = (caseId, assets, setAssets) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('cases')
    .doc(caseId).collection('assets').where(app.firestore.FieldPath.documentId(), 'in', assets).get()
    .then(snapshot => {
      const data = snapshot.docs.map(a => {
        return {
          id: a.id,
          ...a.data()
        }
      })
      setAssets(data)
    })
    .catch(error => {
      console.error(error)
    })

  createAsset = (asset) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('assets')
    .add({
      deleted: false,
      ...asset
    })
    .catch(e => {
      console.log('Error writing document', e)
    })

  updateAsset = (assetId, update) => this.fs
    .collection('customers').doc(this.auth.currentUser.uid)
    .collection('assets').doc(assetId)
    .update(update)

  deleteAsset = (assetId) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('assets')
    .doc(assetId)
    .update({
      deleted: true
    })
    .catch(e => {
      console.log('Error deleting document', e)
    })

  createValuation = (assetId, valuation) => this.fs.collection('customers')
    .doc(this.auth.currentUser.uid).collection('assets')
    .doc(assetId).collection('valuations')
    .add(valuation)
    .catch(e => {
      console.log('Error writing document', e)
    })

  updateValuation = (assetId, valuationId, valuation) => this.fs
    .collection('customers').doc(this.auth.currentUser.uid)
    .collection('assets').doc(assetId)
    .collection('valuations').doc(valuationId).update(valuation)

  deleteValuation = (assetId, valuationId) => this.fs
    .collection('customers').doc(this.auth.currentUser.uid)
    .collection('assets').doc(assetId)
    .collection('valuations').doc(valuationId)
    .delete()

}

export default Firebase;
