import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { fetchBegin } from '../../redux/products/actions'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.light,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
})

class PaymentBase extends React.Component {

  componentDidMount() {
    console.log('go and get products and prices')
    this.props.dispatch(fetchBegin())
  }

  render() {
    const { classes, prices } = this.props

    return (
      <div>

        {/* Start plans */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-start" justify="center">

            {prices.map((p) => (
              <Grid item key={p.id} xs={12} md={4}>
                <Card>
                  <CardHeader title={p.name} subheader={p.description}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                </Card>

                <CardContent>
                  {p.prices.map(r => (
                    <div key={r.id} className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${r.unit_amount/100}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /{r.interval}
                      </Typography>
                    </div>
                  ))}
                </CardContent>

                <CardActions>
                  <Button fullWidth variant='contained' color="primary" onClick={() => this.selectProduct(p)}>
                    Select
                  </Button>
                </CardActions>
              </Grid>
            ))}

          </Grid>

        </Container>
        {/* End plans */}

      </div>
    )
  }

  selectProduct = (p) => {
    console.log('do something with', p)
  }

}

const mapStateToProps = state => ({
  prices: getValueFromState(state, stateKeys.PRICES),
})

const Payment = compose(
  withFirebase,
  withStyles(styles),
  connect(mapStateToProps),
)(PaymentBase)


export default Payment
