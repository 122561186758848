import AppBar from '@material-ui/core/AppBar'
import LinearProgress from '@material-ui/core/LinearProgress'
import React from 'react'
import SignOutButton from '../SignOut'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DRAWER_WIDTH } from '../../constants/toolbars'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { unsetCase } from '../../redux/case/actions'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import * as ROUTES from '../../constants/routes'

const styles = theme => ({
  appBar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: theme.zIndex.drawer + 1,
  },
  img: {
    cursor: 'pointer',
    margin: theme.spacing(1.5),
    width: `calc(${DRAWER_WIDTH/4}px - (${theme.spacing(1)}px * 3))`,
  },
  pageTitle: {
    flex: 1,
  },
  toolBar: {
    paddingLeft: '0',
  },
})

class InternalAppBar extends React.Component {

  logoClick = () => {
    this.props.history.push(ROUTES.LANDING)
    this.props.dispatch(unsetCase())
  }

  render() {
    const { checkoutBusy, classes } = this.props

    const progress = checkoutBusy ? <LinearProgress color="secondary" /> : null

    return (
      <div>
        <AppBar position="fixed" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <img className={classes.img} src="/mycasetrack_logo192.png" alt="My Case Track" onClick={this.logoClick} />
            <Typography variant="h5" color="primary" className={classes.pageTitle} >
              My Case Track
            </Typography>
            <SignOutButton />
          </Toolbar>
          {progress}
        </AppBar>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkoutBusy: getValueFromState(state, stateKeys.CHECKOUT_SESSION_BUSY),
})

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
)(InternalAppBar)
