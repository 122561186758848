import AddIcon from '@material-ui/icons/Add'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fab from '@material-ui/core/Fab'
import NewPersonDialog from "./NewPersonDialog"
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { clearNewPersonDialog, closeNewPersonDialog, openNewPersonDialog, updateNewPerson } from '../../redux/people/actions'
import { withAuthentication, withAuthorisation, withSubscription } from '../Session'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/core/styles'
import {getValueFromState, stateKeys} from "../../redux/utils/stateUtils";
import withTitle from "../Decorators/withTitle";

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
  },
  paper: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
})

class PeoplePage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      people: []
    }
  }

  componentDidMount() {
    const { firebase } = this.props
    firebase.getPeople(people => {
      this.setState({
        people: people
      })
    })
  }

  openNewPersonDialog = () => {
    const { dispatch } = this.props
    dispatch(openNewPersonDialog())
  }

  closeNewPersonDialog = () => {
    const { dispatch } = this.props
    dispatch(closeNewPersonDialog())
  }

  clearNewPersonDialog = () => {
    const { dispatch } = this.props
    dispatch(clearNewPersonDialog())
  }

  createNewPerson = person => {
    const { dispatch, firebase } = this.props
    firebase.createPerson(person)
        .then(() => {
          firebase.getPeople(
            people => {
              this.setState({
                people: people
              })
            })
        })
    dispatch(closeNewPersonDialog())
    dispatch(clearNewPersonDialog())
  }

  onChange = (person) => {
    const { dispatch } = this.props
    dispatch(updateNewPerson(person))
  }

  render() {
    const { classes, dialogOpen, person } = this.props
    const { people } = this.state
    return (
      <div className={classes.content}>
        <CssBaseline />
        <Paper className={classes.paper} elevation={3}>
          <Table className={classes.table} aria-label="people table">
            <TableHead>
              <TableRow>
                <TableCell>
                  First Name
                </TableCell>
                <TableCell align="left">
                  Last Name
                </TableCell>
                <TableCell align="left">
                  DOB
                </TableCell>
                <TableCell align="left">
                  Relationship
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                people.map((p, index) => {
                  const dob = typeof(p.dob) === "undefined" || p.dob === null ? '' :  (new Date(p.dob)).toLocaleDateString()
                  return (
                    <TableRow key={index}>
                      <TableCell>{p.firstName}</TableCell>
                      <TableCell>{p.lastName}</TableCell>
                      <TableCell>{dob}</TableCell>
                      <TableCell>{p.relationship}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Paper>
        <Fab color="secondary" aria-label="add" className={classes.fab} onClick={() => this.openNewPersonDialog()}>
          <AddIcon />
        </Fab>
        <NewPersonDialog open={dialogOpen} save={this.createNewPerson} close={this.closeNewPersonDialog} clear={this.clearNewPersonDialog} person={person} onChange={this.onChange} />
      </div>
    )
  }
}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  dialogOpen: getValueFromState(state, stateKeys.NEW_PERSON_DIALOG_OPEN),
  person: getValueFromState(state, stateKeys.NEW_PERSON).toJS(),
})

export default compose(
  withAuthentication,
  withAuthorisation(condition),
  withSubscription,
  withFirebase,
  withStyles(styles),
  withTitle("People"),
  connect(mapStateToProps),
)(PeoplePage)
