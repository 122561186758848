import AddIcon from '@material-ui/icons/Add'
import CaseTableRow from './CaseTableRow'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fab from '@material-ui/core/Fab'
import NewCaseDialog from './NewCaseDialog'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {orderList} from '../../utils/CaseUtils'
import {compose} from 'recompose'
import {openNewCaseDialog} from '../../redux/newCase/actions'
import {unsetCase} from '../../redux/case/actions'
import {withAuthentication, withAuthorisation, withSubscription} from '../Session'
import {withFirebase} from '../Firebase'
import {withStyles} from '@material-ui/core/styles'
import withTitle from "../Decorators/withTitle";

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
  },
  paper: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
})

class Landing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      product: null,
      cases: [],
      errors: [],
      order: {
        by: 'name',
        direction: 'asc'
      }
    }
    this.orderBy = this.orderBy.bind(this)
  }

  componentDidMount() {
    const {dispatch, firebase} = this.props
    dispatch(unsetCase())
    firebase.getStripeRole(
      product => {
        this.setState({
          product: product
        })
      },
      error => {
        const {errors} = this.state
        errors.push(error)
        this.setState({
          errors: errors
        })
      })

    firebase.getCases(
      cases => {
        this.setState({
          cases: cases.sort(orderList(this.state.order.by, this.state.order.direction))
        })
      },
      error => {
        const {errors} = this.state
        errors.push(error)
        this.setState({
          errors: errors
        })
      })
  }

  openNewCaseDialog = () => {
    const {dispatch} = this.props
    dispatch(openNewCaseDialog())
  }

  saveNewCase = (_case) => {
    const {firebase} = this.props
    firebase.createCase({
      opened: new Date().getTime(),
      ..._case
    })
      .then(() => {
        firebase.getCases(
          cases => {
            this.setState({
              cases: cases
            })
          },
          error => {
            const {errors} = this.state
            errors.push(error)
            this.setState({
              errors: errors
            })
          })
      })
  }

  deleteCase = () => {
    console.warn('Reserved for future use')
    // this.props.firebase.updateCase(c.id, {deleted: true})
    //   .then(() => {
    //     this.props.firebase.getCases(
    //       cases => {
    //         this.setState({
    //           cases: cases
    //         })
    //       },
    //       error => {
    //         const { errors } = this.state
    //         errors.push(error)
    //         this.setState({
    //           errors: errors
    //         })
    //       })
    //   })
  }

  orderBy = (by) => {
    const {cases, order} = this.state

    let direction
    if (by === order.by) {
      direction = order.direction === 'asc' ? 'desc' : 'asc'
    } else {
      direction = 'asc'
    }

    this.setState({
      cases: cases.sort(orderList(by, direction)),
      order: {
        by: by,
        direction: direction
      }
    })

  }

  render() {
    const {classes} = this.props
    const {cases, order} = this.state
    return (
      <div className={classes.content}>
        <CssBaseline/>
        <Paper className={classes.paper} elevation={3}>
          <Table className={classes.table} aria-label="case table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel id="name" direction={order.direction} onClick={() => this.orderBy("name")}
                                  active={order.by === "name"}>
                    Case
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel id="opened" direction={order.direction} onClick={() => this.orderBy("opened")}
                                  active={order.by === "opened"}>
                    Opened
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cases.map((c, index) => {
                return <CaseTableRow key={index} c={c} delete={this.deleteCase}/>
              })}
            </TableBody>
          </Table>
        </Paper>
        <Fab color="secondary" aria-label="add" className={classes.fab} onClick={() => this.openNewCaseDialog()}>
          <AddIcon/>
        </Fab>
        <NewCaseDialog save={this.saveNewCase}/>
      </div>
    )
  }
}

const condition = authUser => !!authUser

export default compose(
  withAuthentication,
  withAuthorisation(condition),
  withSubscription,
  withFirebase,
  withStyles(styles),
  withTitle("Cases"),
)(Landing)
