import Day from './Day'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { monthFrom } from '../../utils/EventUtils'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  indent: {
    paddingLeft: '30px'
  }
})

class Month extends React.Component {

  render() {
    const { classes, month } = this.props
    const calendarMonth = Object.keys(month)[0]
    const days = month[calendarMonth].days
    const thisMonth = monthFrom(calendarMonth - 1)
    return (
      <div>
        <Typography component="h5" variant="h5" align="left" color="textPrimary" className={classes.indent}>
          { thisMonth }
        </Typography>
        {
          days.map((d, index) => {
            return (
              <Day key={index} day={d} />
            )
          })
        }
      </div>
    )
  }

}

export default compose(
  withStyles(styles)
)(Month)
