import ExternalAppBar from './externalAppBar'
import InternalAppBar from './internalAppBar'
import React from 'react'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'

const Navigation = ({ authUser }) => (
  authUser ? <InternalAppBar authUser={authUser} /> : <ExternalAppBar />
)

const mapStateToProps = state => ({
  authUser: getValueFromState(state, stateKeys.AUTH_USER)
})

export default connect(mapStateToProps)(Navigation)
