import { call, put } from 'redux-saga/effects'
import { fetchSuccess, fetchFailure, fetchError } from './actions'
import ProductsAPI from './api'

export function* fetchProducts(action) {
  try {
    let result = yield call(ProductsAPI.all)
    if(result.ok) {
      yield put(fetchSuccess(result.products))
    } else {
      yield put(fetchFailure(result.error))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}
