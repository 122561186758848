export const OPEN_NEW_PERSON_DIALOG = 'OPEN_NEW_PERSON_DIALOG'
export const CLOSE_NEW_PERSON_DIALOG = 'CLOSE_NEW_PERSON_DIALOG'
export const CLEAR_NEW_PERSON_DIALOG = 'CLEAR_NEW_PERSON_DIALOG'
export const UPDATE_NEW_PERSON = 'UPDATE_NEW_PERSON'

export const openNewPersonDialog = () => ({
  type: OPEN_NEW_PERSON_DIALOG
})

export const closeNewPersonDialog = () => ({
  type: CLOSE_NEW_PERSON_DIALOG
})

export const clearNewPersonDialog = () => ({
  type: CLEAR_NEW_PERSON_DIALOG
})

export const updateNewPerson = update => ({
  type: UPDATE_NEW_PERSON,
  update: update
})
