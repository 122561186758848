import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'
import {EMPTY_ASSET, EMPTY_VALUATION} from "../../constants/assets";

let initialState = fromJS({
  assetDialogOpen: false,
  asset: {},
  valuationDialogOpen: false,
  valuation: {}
})

const assets = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_ASSET_DIALOG: {
      const withAsset = setValueInState(state, stateKeys.ASSET, action.payload.asset)
      return setValueInState(withAsset, stateKeys.ASSET_DIALOG_OPEN, true)
    }

    case actions.CLOSE_ASSET_DIALOG: {
      return setValueInState(state, stateKeys.ASSET_DIALOG_OPEN, false)
    }

    case actions.CLEAR_ASSET_DIALOG: {
      return setValueInState(state, stateKeys.ASSET, EMPTY_ASSET)
    }

    case actions.UPDATE_ASSET: {
      return setValueInState(state, stateKeys.ASSET, action.update)
    }

    case actions.OPEN_VALUATION_DIALOG: {
      const withValuation = setValueInState(state, stateKeys.VALUATION, action.payload.valuation)
      return setValueInState(withValuation, stateKeys.VALUATION_DIALOG_OPEN, true)
    }

    case actions.CLOSE_VALUATION_DIALOG: {
      return setValueInState(state, stateKeys.VALUATION_DIALOG_OPEN, false)
    }

    case actions.CLEAR_VALUATION_DIALOG: {
      return setValueInState(state, stateKeys.VALUATION, EMPTY_VALUATION)
    }

    case actions.UPDATE_VALUATION: {
      return setValueInState(state, stateKeys.VALUATION, action.update)
    }

    case actions.SELECT_ASSET: {
      return setValueInState(state, stateKeys.ASSET, action.payload.asset)
    }

    default: {
      return state
    }

  }

}

export default assets
