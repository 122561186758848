import React from 'react';
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import * as ROUTES from '../../constants/routes'

const withSubscription = Component => {
  class WithSubscription extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        show: false
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.hasSubscribed(
        subscribed => {
          if(!subscribed) {
            this.props.history.push(ROUTES.PRODUCTS)
          } else {
            this.setState({
              show: true
            })
          }
        })
    }

    componentWillUnmount() {
      this.listener = null
    }

    render() {
      const { show } = this.state
      return show ? <Component {...this.props} /> : null
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithSubscription)
}

export default withSubscription
