import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/styles'
import { EMPTY_PRICE } from '../../constants/stripe'

const styles = theme => ({
  select: {
    width: '100%',
  },
})

class Prices extends React.Component {

  render() {
    const { classes, onChange, prices } = this.props
    const val = Object.assign({}, EMPTY_PRICE, prices[0])
    return (
      <Select className={classes.select} value={val.id} onChange={onChange}>
        {prices.map(p => <MenuItem key={p.id} value={p.id}>£{p.unit_amount/100}/{p.interval}</MenuItem>)}
      </Select>
    )
  }

}

export default compose(
  withStyles(styles),
)(Prices)
