import { all, fork, takeLatest } from 'redux-saga/effects'
import * as userActions from '../user/actions'
import * as productListActions from '../products/actions'
import { createUser } from '../user/saga'
import { fetchProducts } from '../products/saga'

export function* sagas(firebase) {
  yield all([
    fork(userWatcher, firebase),
    fork(productListWatcher),
  ])
}

function* userWatcher(firebase) {
  yield takeLatest(userActions.CREATE_USER_BEGIN, createUser, firebase)
}

function* productListWatcher() {
  yield takeLatest(productListActions.FETCH_PRODUCTS_BEGIN, fetchProducts)
}
