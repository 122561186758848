import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withAuthentication, withAuthorisation, withEmailVerification } from '../Session'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({})

class Home extends React.Component {

  needsEmailVerification = (authUser) =>
    authUser
    && !authUser.emailVerified
    && authUser.providerData.map(provider => provider.providerId).includes('password')

  render() {
    return (
      <div>
        <Typography variant="h2" gutterBottom>
          Cases
        </Typography>
      </div>
    )
  }

}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  authUser: getValueFromState(state, stateKeys.AUTH_USER),
})

export default compose(
  withStyles(styles),
  withAuthentication,
  withAuthorisation(condition),
  withEmailVerification,
  connect(mapStateToProps),
)(Home)
