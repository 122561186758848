import React from 'react'
import {ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

class Asset extends React.Component {
  render() {
    const {asset, index, selected} = this.props
    return (
      <ListItem button selected={selected === index}
        onClick={() => this.props.handleItemClick(index)} >
        <ListItemText primary={asset.name}/>
        <ListItemSecondaryAction>
          <IconButton aria-label="edit" onClick={() => this.props.handleItemEdit(index)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => this.props.handleItemDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default Asset