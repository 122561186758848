import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import {DatePicker} from "@material-ui/pickers"
import {RELATIONSHIPS} from "../../constants/people";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

const styles = () => ({ })

class NewPersonDialog extends React.Component {

  handleChange = (e) => {
    const update = Object.assign({}, this.props.person, { [e.target.name]: e.target.value })
    this.props.onChange(update)
  }

  handleDobChange = (e) => {
    const date = (new Date(e)).setHours(0,0,0,0)
    const update = Object.assign({}, this.props.person, { dob: +date })
    this.props.onChange(update)
  }

  relationships = () => {
    let defaultRelations = [
      <MenuItem value="" key={0}>
        <em>None</em>
      </MenuItem>
    ]

    let consfiguredRelationships = RELATIONSHIPS.map((r, index) => {
      return <MenuItem key={index+1} value={r}>{r}</MenuItem>
    })

    let relationships = defaultRelations.concat(consfiguredRelationships)
    return relationships
  }

  render() {
    const { open } = this.props
    const { firstName, lastName, dob, relationship } = this.props.person

    return (
      <Dialog open={open} onClose={this.props.close} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New Person</DialogTitle>
        <DialogContent>

          <form>
            <TextField autoFocus value={firstName} margin="dense" id="First name"
              label="First Name" name="firstName" onChange={this.handleChange} fullWidth />

            <TextField value={lastName} margin="dense" id="Last name"
              label="Last Name" name="lastName" onChange={this.handleChange} fullWidth />

            <DatePicker value={dob} margin="dense" label="Date of birth" name="dob" disableFuture openTo="year"
              format="dd/MM/yyyy" views={["year", "month", "date"]} onChange={this.handleDobChange} />

            <FormControl fullWidth>
              <InputLabel id="relationship-label">Relationship</InputLabel>
              <Select labelId="relationship-label" value={relationship} name="relationship"
                onChange={this.handleChange} >
                {this.relationships()}
              </Select>
            </FormControl>
          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.clear} color="primary">
            Clear
          </Button>
          <Button onClick={() => this.props.save(this.props.person)} color="secondary" variant="contained">
            Create
          </Button>
          <Button onClick={this.props.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

export default compose(
  connect(),
  withStyles(styles),
)(NewPersonDialog)
