export const OPEN_EVENT_DETAIL_DIALOG = 'OPEN_EVENT_DETAIL_DIALOG'
export const CLOSE_EVENT_DETAIL_DIALOG = 'CLOSE_EVENT_DETAIL_DIALOG'
export const CLEAR_EVENT_DETAIL_DIALOG = 'CLEAR_EVENT_DETAIL_DIALOG'
export const UPDATE_NEW_EVENT = 'UPDATE_NEW_EVENT'

export const openEventDetailDialog = event => ({
  type: OPEN_EVENT_DETAIL_DIALOG,
  event: event,
})

export const closeEventDetailDialog = () => ({
  type: CLOSE_EVENT_DETAIL_DIALOG
})

export const clearEventDetailDialog = () => ({
  type: CLEAR_EVENT_DETAIL_DIALOG
})

export const updateNewEvent = event => ({
  type: UPDATE_NEW_EVENT,
  event: event
})
