import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function VerifyEmail() {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Verify your email
      </Typography>
      <Typography variant="body1">
        Check your emails (spam folder included) for a
        confirmation email or send another confirmation email.
      </Typography>
    </div>
  )
}
