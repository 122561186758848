import Button from '@material-ui/core/Button'
import { DateTimePicker } from "@material-ui/pickers"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { clearNewEventDialog, closeNewEventDialog, updateNewEvent } from '../../redux/newEvent/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({ })

class NewEventDialog extends React.Component {

  handleClose = () => {
    this.props.dispatch(closeNewEventDialog())
  }

  handleChange = (e) => {
    const { event, dispatch } = this.props
    const update = Object.assign({}, event, { [e.target.name]: e.target.value })
    dispatch(updateNewEvent(update))
  }

  handleDateChange = (e) => {
    const { event, dispatch } = this.props
    const date = new Date(e)
    const update = Object.assign({}, event, { when: +date })
    dispatch(updateNewEvent(update))
  }

  handleClear = () => {
    const { dispatch } = this.props
    dispatch(clearNewEventDialog())
  }

  handleSave = () => {
    const { dispatch, event, save } = this.props
    const when = new Date(event.when)
    const withDates
      = Object.assign(
        {},
        event,
        {
          created : +(new Date()),
          year : when.getFullYear(),
          month : when.getMonth() + 1,
          day : when.getDate()
        }
      )
    save(withDates)
    dispatch(closeNewEventDialog())
  }

  render() {
    const { event, open } = this.props
    const title = event.name.length === 0 ? 'Untitled Event' : event.name
    const disableSave = event.name.length === 0 || event.description.length === 0

    return (
      <Dialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>

          <form>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField autoFocus value={event.name} margin="dense"
                  label="Event Name" name="name" onChange={this.handleChange} fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimePicker label="When" value={event.when} margin="dense"
                  onChange={this.handleDateChange} animateYearScrolling fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField value={event.description} margin="dense" id="description"
                  label="Detail" name="description" onChange={this.handleChange}
                  fullWidth multiline />
              </Grid>
            </Grid>

          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={() => this.handleSave()} color="secondary" variant="contained" disabled={disableSave}>
            Create
          </Button>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.NEW_EVENT_DAILOG_OPEN),
  event: getValueFromState(state, stateKeys.NEW_EVENT).toJS(),
})

export default compose(
  withFirebase,
  connect(mapStateToProps),
  withStyles(styles),
)(NewEventDialog)
