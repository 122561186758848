export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE'
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'

export const fetchBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN,
})

export const fetchSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products }
})

export const fetchFailure = failure => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { failure }
})

export const fetchError = error => ({
  type: FETCH_PRODUCTS_ERROR,
  payload: { error }
})

export const selectProduct = p => ({
  type: SELECT_PRODUCT,
  payload: { p }
})
