export const EMPTY_CASE = {
  name: '',
  description: '',
  deleted: false
}

export const EMPTY_PERSON = {
  firstName: '',
  lastName: '',
  dob: (new Date()).setHours(0,0,0,0),
  relationship: '',
}

export const EMPTY_EVENT = {
  name: '',
  description: ''
}
