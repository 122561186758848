import React from 'react'
import {Typography} from "@material-ui/core";

const withTitle = title => Component => {
  class WithTitle extends React.Component {
    render() {
      return (
        <div>
          <Typography style={{paddingLeft: 32}} variant="h3">{title}</Typography>
          <Component {...this.props} />
        </div>
      )
    }
  }
  return WithTitle
}
export default withTitle
