import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import styles from './SignIn.css' // This uses CSS modules.
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
)

class SignInFormBase extends React.Component {
  uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: ROUTES.LANDING,
    signInOptions: [
      this.props.firebase.googleAuthProvider,
      this.props.firebase.emailAuthProvider
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        return true
      },
    },
  }

  state = {
    isSignedIn: undefined,
  }

  componentDidMount() {
    this.unregisterAuthObserver = this.props.firebase.auth.onAuthStateChanged((user) => {
      this.setState({isSignedIn: !!user})
    })
  }

  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  render() {
    return (
      <div className={styles.container}>
        {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
          <div>
            <StyledFirebaseAuth
              className={styles.firebaseUi}
              uiConfig={this.uiConfig}
              firebaseAuth={this.props.firebase.auth} />
          </div>
        }
        {this.state.isSignedIn &&
          <div className={styles.signedIn}>
            Hello - You are now signed In!
          </div>
        }
      </div>
    )
  }
}

const SignInForm = compose(
  withFirebase,
)(SignInFormBase)

export default SignInPage

export { SignInForm }
