import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function ConfirmationSent() {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Confirmation sent
      </Typography>
      <Typography variant="body1">
        Check your emails (spam folder included) for a confirmation email.
        Refresh this page once you have confirmed your email address.
      </Typography>
    </div>
  )
}
