export const CURRENCIES = [
  {
    name: 'USD',
    symbol: '$'
  },
  {
    name: 'GBP',
    symbol: '£'
  },
  {
    name: 'RSD',
    symbol: 'Дин.'
  },
  {
    name: 'EUR',
    symbol: '€'
  }
]

export const DEFAULT_CURRENCY = CURRENCIES[0]

export const EMPTY_VALUATION = {
  date: (new Date()).setHours(0,0,0,0),
  currency: DEFAULT_CURRENCY.name,
  type: '',
  value: '',
}

export const EMPTY_ASSET = {
  name: '',
  description: '',
}