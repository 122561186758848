import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { closeCaseNameDialog, updateCaseName } from '../../redux/caseName/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'

class CaseNameDialog extends React.Component {

  handleChange = (e) => {
    const { dispatch } = this.props
    dispatch(updateCaseName({
      payload: {
        text: e.target.value
      }
    }))
  }

  closeMe = () => {
    const { dispatch } = this.props
    dispatch(closeCaseNameDialog())
  }

  save = () => {
    const { dispatch, save, text } = this.props
    save({
      name: text
    })
    dispatch(closeCaseNameDialog())
  }

  render() {
    const { open, text } = this.props
    const valid = text.length > 0

    return (
      <Dialog open={open} onClose={this.closeMe} maxWidth="sm" fullWidth={true} aria-labelledby="case-name-dialog">
        <DialogContent>

          <form>
            <TextField autoFocus value={text} margin="dense"
              id="name" label="Name" name="name"
              onChange={this.handleChange} fullWidth />
          </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={this.save} color="secondary" variant="contained" disabled={!valid}>
            Save
          </Button>
          <Button onClick={this.closeMe} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}

CaseNameDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.CASE_NAME_DIALOG_OPEN),
  text: getValueFromState(state, stateKeys.CASE_NAME_TEXT)
})

export default compose(
  connect(mapStateToProps),
)(CaseNameDialog)
