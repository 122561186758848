import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { padTime } from '../../utils/EventUtils'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  bottom: {
    marginBottom: theme.spacing(2)
  }
})

class Event extends React.Component {

  render() {
    const { classes, event } = this.props
    const when = new Date(event.when)
    const time = `${padTime(when.getHours())}:${padTime(when.getMinutes())}`
    return (
      <div className={classes.bottom}>
        <Typography component="h6" variant="h6" align="left" color="textPrimary">
          { time } - { event.name }
        </Typography>
      </div>
    )
  }

}

export default compose(
  withStyles(styles)
)(Event)
