import App from './components/App'
import createSagaMiddleware from 'redux-saga'
import DateFnsUtils from '@date-io/date-fns'
import Firebase, { FirebaseContext } from './components/Firebase'
import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Provider } from 'react-redux'
import { rootReducer } from './redux/reducer'
import { sagas } from './redux/sagas'
import * as serviceWorker from './serviceWorker'
import './index.css'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "'Raleway', sans-serif"
    ],
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#c3c6c8',
      main: '#939597',
      dark: '#656769',
    },
    secondary: {
      light: '#ffff80',
      main: '#f5df4d',
      dark: '#bfae0e',
    },
  },
  mixins: {
    myCaseTrackToolbar: {
      minHeight: 65
    }
  }
})

const firebase = new Firebase()

const sagaMiddleware = createSagaMiddleware()
let store = createStore(rootReducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(sagas, firebase)

const stripePromise = loadStripe('pk_test_51HoECuHMSSox4e0RAiZmwk8Akj8W0BfywTCfnXYHqjkbNFOF5yFTlM6fH9np9Kbvy4pk3rcTNQklapER5sy7AsF300whsOIuyM')

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <FirebaseContext.Provider value={firebase}>
            <App />
          </FirebaseContext.Provider>
        </Elements>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
