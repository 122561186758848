import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Product from './Product'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { createCheckoutSessionBegin, createCheckoutSessionFailure, createCheckoutSessionSuccess } from '../../redux/checkout/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'
import { withAuthentication, withAuthorisation } from '../Session'

const styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.light,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
})

class ProductsBase extends React.Component {
  constructor(props) {
    super(props)
    this.selectProduct = this.selectProduct.bind(this)
    this.state = {
      products: []
    }
  }

  componentDidMount() {
    this.props.firebase.getProducts(products => {
      this.setState({
        products: products
      })
    })
  }

  render() {
    const { checkoutBusy, classes } = this.props
    const { products } = this.state

    return (
      <React.Fragment>
        <CssBaseline />

        {/* Start hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
            Choose a plan that’s right for you
          </Typography>
        </Container>
        {/* End hero unit */}

        {/* Start plans */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-start" justify="center">

            {products.map((p) => (
              <Product key={p.id} product={p} checkoutBusy={checkoutBusy} subscribe={this.selectProduct} />
            ))}

          </Grid>
        </Container>
        {/* End plans */}

      </React.Fragment>
    )
  }

  async selectProduct(productId, priceId) {
    this.props.dispatch(createCheckoutSessionBegin())

    const docRef = await this.props.firebase.firestore()
      .collection('customers')
      .doc(this.props.authUser.uid)
      .collection('checkout_sessions')
      .add({
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      })

    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data()
      if (error) {
        this.props.dispatch(createCheckoutSessionFailure(error.message))
      }
      if (sessionId) {
        this.props.firebase.stripe().redirectToCheckout({ sessionId })
        this.props.dispatch(createCheckoutSessionSuccess())
      }
    })

  }
}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  authUser: getValueFromState(state, stateKeys.AUTH_USER),
  checkoutBusy: getValueFromState(state, stateKeys.CHECKOUT_SESSION_BUSY),
})

const Products = compose(
  withFirebase,
  withStyles(styles),
  withAuthentication,
  withAuthorisation(condition),
  connect(mapStateToProps),
)(ProductsBase)

export default Products
