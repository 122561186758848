export const OPEN_CASE_NAME_DIALOG = 'OPEN_CASE_NAME_DIALOG'
export const CLOSE_CASE_NAME_DIALOG = 'CLOSE_CASE_NAME_DIALOG'
export const UPDATE_CASE_NAME = 'UPDATE_CASE_NAME'

export const openCaseNameDialog = payload => ({
  type: OPEN_CASE_NAME_DIALOG,
  ...payload
})

export const closeCaseNameDialog = () => ({
  type: CLOSE_CASE_NAME_DIALOG
})

export const updateCaseName = payload => ({
  type: UPDATE_CASE_NAME,
  ...payload
})
