import CaseNameDialog from './CaseNameDialog'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { openCaseNameDialog } from '../../redux/caseName/actions'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  edit: {
    marginLeft: theme.spacing(1)
  }
})

class CaseName extends React.Component {

  constructor(props) {
    super(props)
    this.openDialog = this.openDialog.bind(this)
  }

  openDialog = () => {
    const { dispatch, selectedCase } = this.props
    dispatch(openCaseNameDialog({
      payload: {
        text: selectedCase.name
      }
    }))
  }

  render() {
    const { classes, open, save, selectedCase, variant } = this.props
    return (
      <div style={{display: 'flex', alignItems: 'flex-end', paddingLeft: 32}}>
        <Typography variant={variant}>{selectedCase.name}</Typography>
        <IconButton className={classes.edit} aria-label="edit" size="small" onClick={() => this.openDialog()}>
          <EditIcon fontSize="inherit" />
        </IconButton>
        <CaseNameDialog open={open} save={save} text={selectedCase.name} />
      </div>
    )
  }

}

CaseName.propTypes = {
  open: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  selectedCase: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.CASE_NAME_DIALOG_OPEN),
  selectedCase: getValueFromState(state, stateKeys.SELECTED_CASE).toJS(),
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CaseName)
