import AddIcon from '@material-ui/icons/Add'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fab from '@material-ui/core/Fab'
import MuiLink from "@material-ui/core/Link"
import NewEventDialog from './NewEventDialog'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Year from './Year'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { groupByDay, groupByMonth, groupByYear } from '../../utils/EventUtils'
import { Link } from 'react-router-dom'
import { openNewEventDialog } from '../../redux/newEvent/actions'
import { selectCase } from '../../redux/case/actions'
import { unsetCase } from '../../redux/case/actions'
import { withAuthentication, withAuthorisation, withSubscription } from '../Session'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/core/styles'
import * as ROUTES from '../../constants/routes'

const styles = theme => ({
  breadcrumbs: {
    margin: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2),
  },
  fab: {
    bottom: theme.spacing(2),
    left: theme.spacing(9),
    position: 'absolute',
  },
  paper: {
    margin: theme.spacing(2),
  },
  rootLink: {
    cursor: 'pointer',
  }
})

class TimelinePage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      events: []
    }
  }

  openNewEventDialog = () => {
    this.props.dispatch(openNewEventDialog())
  }

  componentDidMount() {
    const { id } = this.props.match.params
    const { dispatch, firebase } = this.props
    firebase.getCase(id, c => {
      dispatch(selectCase(c))
    })
    firebase.getEvents(id, events => {
      this.setState({
        events: events
      })
    })
  }

  saveNewEvent = (event) => {
    const { id } = this.props.match.params
    const { firebase } = this.props
    firebase.createEvent( id, event )
    firebase.getEvents(id, events => {
      this.setState({
        events: events
      })
    })
  }

  rootClick = () => {
    this.props.history.push(ROUTES.LANDING)
    this.props.dispatch(unsetCase())
  }

  render() {
    const { classes, selectedCase } = this.props
    const { events } = this.state

    var years = []

    const eventsByYear = groupByYear(events)
    if(events.length > 0) {
      for(const year in eventsByYear) {

        var months = []
        const eventsByMonth = groupByMonth(eventsByYear[year])
        for(const month in eventsByMonth) {

          var days = []
          const eventsByDay = groupByDay(eventsByMonth[month])
          for(const day in eventsByDay) {

            const eventsThisDay = eventsByDay[day].sort((a,b) => {
              return new Date(b.when) - new Date(a.when)
            })

            days.push({
              [day]: eventsThisDay
            })

          }

          months.push({
            [month]: {days: days}
          })
        }

        years.push({
          [year]: {months: months}
        })
      }

    }

    return (
      <div className={classes.content}>
        <CssBaseline />
        <Breadcrumbs className={classes.breadcrumbs}>
          <MuiLink color="inherit" onClick={this.rootClick} className={classes.rootLink}>
            Cases
          </MuiLink>
          <MuiLink component={Link} to={`${ROUTES.CASE}/${selectedCase.id}`}>
            { selectedCase.name }
          </MuiLink>
          <Typography color="textPrimary">Events</Typography>
        </Breadcrumbs>
        <Paper className={classes.paper} elevation={3}>
          {
            years.map((y, index) => {
              return (
                <Year key={index} year={y} />
              )
            })
          }
        </Paper>
        <NewEventDialog save={this.saveNewEvent} />
        <Fab color="secondary" aria-label="New event" className={classes.fab} onClick={() => this.openNewEventDialog()}>
          <AddIcon />
        </Fab>
      </div>
    )
  }

}

const condition = authUser => !!authUser

const mapStateToProps = state => ({
  selectedCase: getValueFromState(state, stateKeys.SELECTED_CASE).toJS(),
})

export default compose(
  withAuthentication,
  withAuthorisation(condition),
  withSubscription,
  withFirebase,
  withStyles(styles),
  connect(mapStateToProps),
)(TimelinePage)
