import React from 'react'
import {compose} from 'recompose'
import {withFirebase} from '../Firebase'
import {getValueFromState, stateKeys} from "../../redux/utils/stateUtils";
import {connect} from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {CURRENCIES, DEFAULT_CURRENCY, EMPTY_VALUATION} from "../../constants/assets";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class Valuations extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      assetValuations: null,
      valuations: []
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { firebase, asset } = this.props
    const previousSelectedAsset = Object.assign({}, EMPTY_VALUATION, prevProps.asset)
    const currentSelectedAsset = Object.assign({}, EMPTY_VALUATION, asset)

    if(typeof(currentSelectedAsset.id) !== "undefined" && currentSelectedAsset.id !== previousSelectedAsset.id) {
      this.setState({
        assetValuations: firebase.getAssetValuations(asset.id, valuations => {
          this.setState({
            valuations: valuations
          })
        })

      })
    }
  }

  componentWillUnmount() {
    const { assetValuations } = this.state
    if(assetValuations) {
      assetValuations()
    }
  }

  render() {
    const { valuations } = this.state
    const { asset } = this.props
    return (
      <Table aria-label="valuation table">
        <TableHead>
          <TableRow>
            <TableCell>
              Date
            </TableCell>
            <TableCell align="left">
              Type
            </TableCell>
            <TableCell align="left">
              Value
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            valuations.map((v, index) => {
              const date = (new Date(v.date)).toLocaleDateString()
              const currency = CURRENCIES.filter(c => c.name === v.currency)
              const symbol = currency.length === 0 ? DEFAULT_CURRENCY.symbol : currency[0].symbol
              return (
                <TableRow key={index}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{v.type}</TableCell>
                  <TableCell>{symbol}{v.value}</TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="edit" onClick={() => this.props.handleValuationEdit(v)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.props.handleValuationDelete(asset.id, v.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    )
  }

}

const mapStateToProps = state => ({
  asset: getValueFromState(state, stateKeys.ASSET).toJS()
})

export default compose(
  withFirebase,
  connect(mapStateToProps),
) (Valuations)
