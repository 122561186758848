import CaseDescriptionDialog from './CaseDescriptionDialog'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { openCaseDescriptionDialog } from '../../redux/caseDescription/actions'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  editButton: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    position: 'absolute',
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    position: 'relative',
    whiteSpace: 'pre-line'
  }
})

class CaseDescription extends React.Component {

  constructor(props) {
    super(props)
    this.openDialog = this.openDialog.bind(this)
  }

  openDialog = () => {
    const { dispatch, selectedCase } = this.props
    dispatch(openCaseDescriptionDialog({
      payload: {
        text: selectedCase.description
      }
    }))
  }

  render() {
    const { classes, open, save, selectedCase } = this.props
    return (
      <Paper className={classes.paper}>
        <IconButton className={classes.editButton} aria-label="edit" size="small" onClick={() => this.openDialog()}>
          <EditIcon fontSize="inherit" />
        </IconButton>
        { selectedCase.description }
        <CaseDescriptionDialog open={open} save={save} />
      </Paper>
    )
  }

}

CaseDescription.propTypes = {
  open: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  selectedCase: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  open: getValueFromState(state, stateKeys.CASE_DESCRIPTION_DIALOG_OPEN),
  selectedCase: getValueFromState(state, stateKeys.SELECTED_CASE).toJS(),
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CaseDescription)
