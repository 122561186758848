import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const withAuthorisation = condition => Component => {
  class WithAuthorisation extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return condition(this.props.authUser) ? <Component {...this.props} /> : null
    }
  }

  const mapStateToProps = state => ({
    authUser: getValueFromState(state, stateKeys.AUTH_USER),
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps),
  )(WithAuthorisation);
};

export default withAuthorisation;
