import Event from './Event'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  indent: {
    paddingLeft: '60px'
  }
})

class Day extends React.Component {

  render() {
    const { classes, day } = this.props
    const calendarDay = Object.keys(day)[0]
    const events = day[calendarDay]
    const sortedEvents = events.sort((a, b) => {
      return a.when - b.when
    })
    return (
      <Grid
        container
        direction="row"
        justifycontent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={1}>
          <Typography component="h6" variant="h6" align="left" color="textPrimary" className={classes.indent}>
            { calendarDay }
          </Typography>
        </Grid>
        <Grid item xs={11}>
          {
            sortedEvents.map((e, index) => {
              return (
                <Event key={index} event={e} />
              )
            })
          }
        </Grid>
      </Grid>
    )
  }

}

export default compose(
  withStyles(styles)
)(Day)
