import axios from 'axios'

const URL = 'https://us-central1-my-case-track.cloudfunctions.net/listProducts'

export default class ProductsAPI {

  static all() {
    return new Promise(resolve => {
      axios.get(URL)
      .then(p => {
        resolve({
          ok: true,
          products: p.data
        })
      })
      .catch(e => {
        resolve(e.response.data)
      })
    })
  }
}
