import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Product from './Product'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { withFirebase } from '../Firebase'
import { withStyles } from '@material-ui/styles'
import * as ROUTES from '../../constants/routes'

const styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.light,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
})

class PricingBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: []
    }
  }


  componentDidMount() {
    this.props.firebase.getProducts(products => {
      this.setState({
        products: products
      })
    })
  }

  componentDidUpdate() {
    if(this.props.createSuccess) {
      this.props.history.push(ROUTES.HOME)
    }
  }

  render() {
    const { classes } = this.props
    const { products } = this.state

    return (
      <React.Fragment>
        <CssBaseline />

        {/* Start hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
            Choose a plan that’s right for you
          </Typography>
        </Container>
        {/* End hero unit */}

        {/* Start plans */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-start" justify="center">

            {products.map((p) => (
              <Product key={p.id} product={p} />
            ))}

          </Grid>
        </Container>
        {/* End plans */}

      </React.Fragment>
    )
  }

}

const mapStateToProps = state => ({
  selected: getValueFromState(state, stateKeys.SELECTED_PRODUCT),
  authUser: getValueFromState(state, stateKeys.AUTH_USER),
  createSuccess: getValueFromState(state, stateKeys.CREATE_SUCCESS),
})

const Pricing = compose(
  withFirebase,
  withStyles(styles),
  connect(mapStateToProps),
)(PricingBase)

export default Pricing
