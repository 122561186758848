export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const SET_NEW_USER = 'SET_NEW_USER'
export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const setUser = user => ({
  type: SET_USER,
  payload: { user }
})

export const unsetUser = () => ({
  type: UNSET_USER
})

export const setNewUser = newUser => ({
  type: SET_NEW_USER,
  payload: { newUser }
})

export const createUser = user => ({
  type: CREATE_USER_BEGIN,
  payload: { user }
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: { user }
})

export const createUserFailure = failure => ({
  type: CREATE_USER_FAILURE,
  payload: { failure }
})

export const createUserError = error => ({
  type: CREATE_USER_ERROR,
  payload: { error }
})
