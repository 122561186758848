import { fromJS } from 'immutable'
import { getValueFromState, stateKeys, setValueInState } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  products: [],
  selected: null,
  failure: null,
  error: null
})

const productList = (state = initialState, action) => {

  switch(action.type) {

    case actions.FETCH_PRODUCTS_BEGIN: {
      // start spinner
      return state
    }

    case actions.FETCH_PRODUCTS_SUCCESS: {
      // stop spinner
      return setValueInState(state, stateKeys.PRODUCTS, action.payload.products)
    }

    case actions.FETCH_PRODUCTS_FAILURE: {
      // stop spinner
      return setValueInState(state, stateKeys.PRODUCTS_FAILURE, action.payload.failure)
    }

    case actions.FETCH_PRODUCTS_ERROR: {
      // stop spinner
      return setValueInState(state, stateKeys.PRODUCTS_ERROR, action.payload.error)
    }

    case actions.SELECT_PRODUCT: {
      const oldProducts = Object.assign([], getValueFromState(state, stateKeys.PRODUCTS))
      const newProducts = oldProducts.map(p => {
        return {
          ...p,
          selected: p.product === action.payload.p.product
        }
      })
      const withFlagSet = setValueInState(state, stateKeys.PRODUCTS, newProducts)
      return setValueInState(withFlagSet, stateKeys.SELECTED_PRODUCT, action.payload.p)
    }

    default: {
      return state
    }

  }

}

export default productList
