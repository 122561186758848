import { call, put } from 'redux-saga/effects'
import { createUserSuccess, createUserFailure, createUserError } from './actions'
import UserAPI from './api'

export function* createUser(firebase, action) {
  try {
    let result = yield call(UserAPI.createUser, action, firebase)
    if(result.ok) {
      yield put(createUserSuccess(result.user))
    } else {
      yield put(createUserFailure(result.error))
    }
  } catch (error) {
    yield put(createUserError(error))
  }
}
