import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
  },
  clearToolBar: theme.mixins.myCaseTrackToolbar,
}))

const AuthLayout = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.clearToolBar}/>
      {props.children}
    </div>
  )
}

const NonAuthLayout = props => {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.clearToolBar}/>
      {props.children}
    </div>
  )
}

const PageStructure = ({ authUser, ...props }) => (
  authUser ? <AuthLayout authUser={authUser} {...props} /> : <NonAuthLayout {...props} />
)

const mapStateToProps = state => ({
  authUser: getValueFromState(state, stateKeys.AUTH_USER)
})

export default compose(
  connect(mapStateToProps),
)(PageStructure)
