import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import * as ROUTES from '../../constants/routes'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    height: '24px',
  },
  logoContainer: {
    flex: 1,
  },
})

class ExternalAppBar extends React.Component {


  render() {
    const { classes } = this.props

    const logo = (
      <div className={classes.logoContainer} >
        <a title="mycasetrack.com" href="http://mycasetrack.com" target="_blank" rel="noopener noreferrer">
          <img className={classes.logo} src="/mycasetrack_logo192.png" alt="mycasetrack.com"/>
        </a>
      </div>
    )

    const pricingButton = (
      <Tooltip title="MyCaseTrack Pricing" placement="bottom">
        <Button variant="outlined" color="primary" component={Link} to={ROUTES.PRICING} style={{marginRight: '5px'}}>Pricing</Button>
      </Tooltip>
    )

    const loginButton = (
      <Tooltip title="Login to MyCaseTrack" placement="bottom">
        <Button variant="outlined" color="primary" component={Link} to={ROUTES.SIGN_IN}>Login</Button>
      </Tooltip>
    )

    return (
      <div className={classes.root}>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar>
            { logo }
            { pricingButton }
            { loginButton }
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles)(ExternalAppBar)
