export const CREATE_CHECKOUT_SESSION_BEGIN = 'CREATE_CHECKOUT_SESSION_BEGIN'
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CREATE_CHECKOUT_SESSION_SUCCESS'
export const CREATE_CHECKOUT_SESSION_FAILURE = 'CREATE_CHECKOUT_SESSION_FAILURE'
export const CREATE_CHECKOUT_SESSION_ERROR = 'CREATE_CHECKOUT_SESSION_ERROR'

export const createCheckoutSessionBegin = () => ({
  type: CREATE_CHECKOUT_SESSION_BEGIN
})

export const createCheckoutSessionSuccess = () => ({
  type: CREATE_CHECKOUT_SESSION_SUCCESS
})

export const createCheckoutSessionFailure = failure => ({
  type: CREATE_CHECKOUT_SESSION_FAILURE,
  payload: { failure }
})

export const createCheckoutSessionError = error => ({
  type: CREATE_CHECKOUT_SESSION_ERROR,
  payload: { error }
})
