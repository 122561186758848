import RootDrawer from './RootDrawer'
import React from 'react'
import { connect } from 'react-redux'
import { getValueFromState, stateKeys } from '../../redux/utils/stateUtils'

const Drawer = ({ authUser }) => (
  authUser ? <RootDrawer /> : null
)

const mapStateToProps = state => ({
  authUser: getValueFromState(state, stateKeys.AUTH_USER)
})

export default connect(mapStateToProps)(Drawer)
