import { EMPTY_CASE } from '../../constants/cases'
import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  selectedCase: EMPTY_CASE,
  caseNameDialogOpen: false,
  caseName: '',
  dialogOpen: false,
  case: {
    id: '',
    name: '',
    description: ''
  },
})

const caseSummary = (state = initialState, action) => {

  switch(action.type) {

    case actions.SELECT_CASE: {
      return setValueInState(state, stateKeys.SELECTED_CASE, action.selectedCase)
    }

    case actions.OPEN_CASE_DETAIL_DIALOG: {
      const withDetail = setValueInState(state, stateKeys.CASE_DETAIL, action.detail)
      return setValueInState(withDetail, stateKeys.CASE_DETAIL_DIALOG_OPEN, true)
    }

    case actions.CLOSE_CASE_DETAIL_DIALOG: {
      return setValueInState(state, stateKeys.CASE_DETAIL_DIALOG_OPEN, false)
    }

    case actions.UPDATE_CASE_DETAIL: {
      return setValueInState(state, stateKeys.CASE_DETAIL, action.update)
    }

    case actions.UNSET_CASE: {
      const unselectCase = setValueInState(state, stateKeys.SELECTED_CASE, EMPTY_CASE)
      return setValueInState(unselectCase, stateKeys.CASE_ID, '')
    }

    default: {
      return state
    }

  }

}

export default caseSummary
