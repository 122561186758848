export const OPEN_NEW_EVENT_DIALOG = 'OPEN_NEW_EVENT_DIALOG'
export const CLOSE_NEW_EVENT_DIALOG = 'CLOSE_NEW_EVENT_DIALOG'
export const CLEAR_NEW_EVENT_DIALOG = 'CLEAR_NEW_EVENT_DIALOG'
export const UPDATE_NEW_EVENT = 'UPDATE_NEW_EVENT'

export const openNewEventDialog = () => ({
  type: OPEN_NEW_EVENT_DIALOG
})

export const closeNewEventDialog = () => ({
  type: CLOSE_NEW_EVENT_DIALOG
})

export const clearNewEventDialog = () => ({
  type: CLEAR_NEW_EVENT_DIALOG
})

export const updateNewEvent = update => ({
  type: UPDATE_NEW_EVENT,
  update: update
})
