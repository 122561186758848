import { fromJS } from 'immutable'
import { stateKeys, setValueInState } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  busy: false,
  error: null
})

const checkout = (state = initialState, action) => {

  switch(action.type) {
    case actions.CREATE_CHECKOUT_SESSION_BEGIN: {
      return setValueInState(state, stateKeys.CHECKOUT_SESSION_BUSY, true)
    }

    case actions.CREATE_CHECKOUT_SESSION_SUCCESS: {
      return setValueInState(state, stateKeys.CHECKOUT_SESSION_BUSY, false)
    }

    case actions.CREATE_CHECKOUT_SESSION_FAILURE: {
      const idle = setValueInState(state, stateKeys.CHECKOUT_SESSION_BUSY, false)
      return setValueInState(idle, stateKeys.CHECKOUT_SESSION_ERROR, action.payload.failure)
    }

    case actions.CREATE_CHECKOUT_SESSION_ERROR: {
      const idle = setValueInState(state, stateKeys.CHECKOUT_SESSION_BUSY, false)
      return setValueInState(idle, stateKeys.CHECKOUT_SESSION_ERROR, action.payload.error)
    }

    default: {
      return state
    }

  }

}

export default checkout
