import { EMPTY_EVENT } from '../../constants/cases'
import { fromJS } from 'immutable'
import { setValueInState, stateKeys } from '../utils/stateUtils'
import * as actions from './actions'

let initialState = fromJS({
  dialogOpen: false,
  event: EMPTY_EVENT,
})

const newEvent = (state = initialState, action) => {

  switch(action.type) {

    case actions.OPEN_NEW_EVENT_DIALOG: {
      const date = new Date()
      const emptyEvent = Object.assign({}, EMPTY_EVENT, { when : date })
      const withEmptyEvent = setValueInState(state, stateKeys.NEW_EVENT, emptyEvent)
      return setValueInState(withEmptyEvent, stateKeys.NEW_EVENT_DAILOG_OPEN, true)
    }

    case actions.CLOSE_NEW_EVENT_DIALOG: {
      const withoutEvent = setValueInState(state, stateKeys.NEW_EVENT, EMPTY_EVENT)
      return setValueInState(withoutEvent, stateKeys.NEW_EVENT_DAILOG_OPEN, false)
    }

    case actions.CLEAR_NEW_EVENT_DIALOG: {
      const date = new Date()
      const emptyEvent = Object.assign({}, EMPTY_EVENT, { opened : date })
      return setValueInState(state, stateKeys.NEW_EVENT, emptyEvent)
    }

    case actions.UPDATE_NEW_EVENT: {
      return setValueInState(state, stateKeys.NEW_EVENT, action.update)
    }

    default: {
      return state
    }

  }

}

export default newEvent
