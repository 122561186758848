export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const CASE = '/case'
export const HOME = '/home'
export const LANDING = '/'
export const PASSWORD_FORGET = '/pw-forget'
export const PAYMENT = '/payment'
export const PRICING = '/pricing'
export const PRODUCTS = '/products'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const TIMELINE = '/timeline'
export const PEOPLE = '/people'
export const ASSETS = '/assets'
