import assets from '../assets/reducer'
import caseDescription from '../caseDescription/reducer'
import caseName from '../caseName/reducer'
import caseSummary from '../case/reducer'
import checkout from '../checkout/reducer'
import newCase from '../newCase/reducer'
import newEvent from '../newEvent/reducer'
import people from '../people/reducer'
import productList from '../products/reducer'
import timeline from '../timeline/reducer'
import userState from '../user/reducer'
import { combineReducers } from 'redux-immutable'

export const rootReducer = combineReducers({
  assets,
  caseDescription,
  caseName,
  caseSummary,
  checkout,
  newCase,
  newEvent,
  people,
  productList,
  timeline,
  userState,
})
